import React, { useState, useEffect } from 'react';
import './App.css';

interface Contact {
  id: number;
  title: string;
  full_name: string;
  contact_type: string;
  contact_status: string;
  designation: {
    id: number;
    name: string;
  };
  department: {
    id: number;
    name: string;
  };
  institute: {
    id: number;
    name: string;
  };
  unit?: {
    id: number;
    name: string;
  } | null;
  division?: {
    id: number;
    name: string;
  } | null;
  mobile_1: string;
  mobile_2: string;
  whatsapp_1: string;
  whatsapp_2: string;
  office_1: string;
  office_2: string;
  fax_1: string;
  fax_2: string;
  email: string | null;
  created_at: string;
  updated_at: string;
}

const ITEMS_PER_PAGE = 9;

const App: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [nameSearch, setNameSearch] = useState('');
  const [designationSearch, setDesignationSearch] = useState('');
  const [organizationSearch, setOrganizationSearch] = useState('');
  const [sortType, setSortType] = useState<'name' | 'designation' | 'department' | 'institute'>('name');
  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchContacts();
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, []);

  useEffect(() => {
    // Reset to first page when search/filter changes
    setCurrentPage(1);
  }, [nameSearch, designationSearch, organizationSearch]);

  const fetchContacts = async () => {
    try {
      setLoading(true);
      setLoadingProgress(0);
      
      // First fetch to get total pages
      const firstResponse = await fetch('https://www.agristaffadmin.krushiradio.lk/api/v1/AGrIStAfFBydhaNUshKabAndAra/contacts?page=1');
      const firstData = await firstResponse.json();
      const totalPages = firstData.meta.last_page;
      
      // Create array of promises for all pages
      const promises = Array.from({ length: totalPages }, (_, i) => 
        fetch(`https://www.agristaffadmin.krushiradio.lk/api/v1/AGrIStAfFBydhaNUshKabAndAra/contacts?page=${i + 1}`)
          .then(res => res.json())
          .then(data => {
            setLoadingProgress(((i + 1) / totalPages) * 100);
            return data.data;
          })
      );

      // Fetch all pages in parallel
      const results = await Promise.all(promises);
      const allContacts = results.flat();

      // Find Director General using exact name
      const directorGeneral = allContacts.find(contact => 
        contact.designation?.name === "Director General of Agriculture"
      );

      // Sort other contacts by name
      const otherContacts = allContacts
        .filter(contact => 
          contact.designation?.name !== "Director General of Agriculture"
        )
        .sort((a, b) => a.full_name.localeCompare(b.full_name));

      // Always put Director General first
      const sortedContacts = directorGeneral 
        ? [directorGeneral, ...otherContacts]
        : otherContacts;

      setContacts(sortedContacts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setLoading(false);
    }
  };

  const filterContacts = (contact: Contact) => {
    // Always include Director General
    if (contact.designation?.name === "Director General of Agriculture") {
      return true;
    }

    const matchesName = contact.full_name.toLowerCase().includes(nameSearch.toLowerCase());
    const matchesDesignation = contact.designation?.name.toLowerCase().includes(designationSearch.toLowerCase());
    const matchesOrganization = (
      contact.department?.name.toLowerCase().includes(organizationSearch.toLowerCase()) ||
      contact.institute?.name.toLowerCase().includes(organizationSearch.toLowerCase()) ||
      (contact.unit?.name?.toLowerCase() || '').includes(organizationSearch.toLowerCase()) ||
      (contact.division?.name?.toLowerCase() || '').includes(organizationSearch.toLowerCase())
    );

    return (
      (!nameSearch || matchesName) &&
      (!designationSearch || matchesDesignation) &&
      (!organizationSearch || matchesOrganization)
    );
  };

  const sortContacts = (a: Contact, b: Contact) => {
    // Always show Director General of Agriculture first
    if (a.designation?.name === "Director General of Agriculture") return -1;
    if (b.designation?.name === "Director General of Agriculture") return 1;

    // Then apply the selected sort type
    switch (sortType) {
      case 'name':
        return a.full_name.localeCompare(b.full_name);
      case 'designation':
        return (a.designation?.name || '').localeCompare(b.designation?.name || '');
      case 'department':
        return (a.department?.name || '').localeCompare(b.department?.name || '');
      case 'institute':
        return (a.institute?.name || '').localeCompare(b.institute?.name || '');
      default:
        return 0;
    }
  };

  // Get filtered and sorted contacts
  const getFilteredAndSortedContacts = () => {
    // First, find Director General in the original contacts
    const directorGeneral = contacts.find(contact => 
      contact.designation?.name === "Director General of Agriculture"
    );

    // Filter and sort other contacts
    const filteredOtherContacts = contacts
      .filter(contact => contact.designation?.name !== "Director General of Agriculture")
      .filter(filterContacts)
      .sort(sortContacts);

    // Always put Director General first if they exist
    return directorGeneral ? [directorGeneral, ...filteredOtherContacts] : filteredOtherContacts;
  };

  const sortedAndFilteredContacts = getFilteredAndSortedContacts();
  const totalPages = Math.ceil(sortedAndFilteredContacts.length / ITEMS_PER_PAGE);
  const paginatedContacts = sortedAndFilteredContacts.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <span className="material-icons">agriculture</span>
          Agri Staff
        </h1>
        <div className="subtitle">
          Contact Directory of the Department of Agriculture & Provincial Departments of Agriculture Sri Lanka
        </div>
        <div className="search-container">
          <div className="search-group">
            <div className="search-wrapper">
              <span className="material-icons search-icon">person_search</span>
              <input
                type="text"
                placeholder="Search by name..."
                value={nameSearch}
                onChange={(e) => setNameSearch(e.target.value)}
                className="search-input"
              />
            </div>
            <div className="search-wrapper">
              <span className="material-icons search-icon">work</span>
              <input
                type="text"
                placeholder="Search by designation..."
                value={designationSearch}
                onChange={(e) => setDesignationSearch(e.target.value)}
                className="search-input"
              />
            </div>
            <div className="search-wrapper">
              <span className="material-icons search-icon">business</span>
              <input
                type="text"
                placeholder="Search by organization..."
                value={organizationSearch}
                onChange={(e) => setOrganizationSearch(e.target.value)}
                className="search-input"
              />
            </div>
          </div>
          <div className="filter-controls">
            <select 
              value={sortType} 
              onChange={(e) => setSortType(e.target.value as typeof sortType)}
              className="filter-select"
            >
              <option value="name">Sort by Name</option>
              <option value="designation">Sort by Designation</option>
              <option value="department">Sort by Department</option>
              <option value="institute">Sort by Institute</option>
            </select>
          </div>
        </div>
      </header>
      <main className="contact-list">
        {loading ? (
          <div className="loading">
            <div className="loading-content">
              <span className="material-icons spinning">refresh</span>
              <div className="loading-text">
                Loading contacts... {Math.round(loadingProgress)}%
                <div className="progress-bar">
                  <div 
                    className="progress-bar-fill" 
                    style={{ width: `${loadingProgress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="results-count">
              Found {sortedAndFilteredContacts.length} contacts
              {sortedAndFilteredContacts.length > ITEMS_PER_PAGE && 
                ` (Showing ${(currentPage - 1) * ITEMS_PER_PAGE + 1}-${Math.min(currentPage * ITEMS_PER_PAGE, sortedAndFilteredContacts.length)})`
              }
            </div>
            {paginatedContacts.map(contact => (
              <div key={contact.id} className="contact-card" onClick={() => setSelectedContact(contact)}>
                <div className="contact-header">
                  <h2>
                    <span className="material-icons">person</span>
                    {contact.title} {contact.full_name}
                  </h2>
                  <span className="status">
                    <span className="material-icons">circle</span>
                    {contact.contact_status}
                  </span>
                </div>
                <div className="contact-details">
                  <p className="designation">
                    <span className="material-icons">work</span>
                    {contact.designation?.name}
                  </p>
                  <p className="department">
                    <span className="material-icons">business</span>
                    {contact.department?.name}
                  </p>
                  <p className="institute">
                    <span className="material-icons">apartment</span>
                    {contact.institute?.name}
                  </p>
                  <div className="contact-info">
                    {contact.mobile_1 && (
                      <p>
                        <span className="material-icons">phone_android</span>
                        <a href={`tel:${contact.mobile_1}`}>{contact.mobile_1}</a>
                      </p>
                    )}
                    {contact.office_1 && (
                      <p>
                        <span className="material-icons">phone</span>
                        <a href={`tel:${contact.office_1}`}>{contact.office_1}</a>
                      </p>
                    )}
                  </div>
                </div>
                <button className="view-more">
                  <span className="material-icons">visibility</span>
                  View Details
                </button>
              </div>
            ))}
            {totalPages > 1 && (
              <div className="pagination">
                <button 
                  className="pagination-button"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <span className="material-icons">chevron_left</span>
                  Previous
                </button>
                <span className="page-info">
                  Page {currentPage} of {totalPages}
                </span>
                <button 
                  className="pagination-button"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                  <span className="material-icons">chevron_right</span>
                </button>
              </div>
            )}
          </>
        )}
      </main>

      {selectedContact && (
        <div className="modal-overlay" onClick={() => setSelectedContact(null)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <button className="close-button" onClick={() => setSelectedContact(null)}>
              <span className="material-icons">close</span>
            </button>
            <div className="modal-header">
              <h2>
                <span className="material-icons">person</span>
                {selectedContact.title} {selectedContact.full_name}
              </h2>
              <span className="status">
                <span className="material-icons">circle</span>
                {selectedContact.contact_status}
              </span>
            </div>
            <div className="modal-body">
              <div className="info-section">
                <h3>
                  <span className="material-icons">badge</span>
                  Position Information
                </h3>
                <p><strong>Contact Type:</strong> {selectedContact.contact_type}</p>
                <p><strong>Designation:</strong> {selectedContact.designation?.name}</p>
                <p><strong>Department:</strong> {selectedContact.department?.name}</p>
                <p><strong>Institute:</strong> {selectedContact.institute?.name}</p>
                {selectedContact.unit && (
                  <p><strong>Unit:</strong> {selectedContact.unit.name}</p>
                )}
                {selectedContact.division && (
                  <p><strong>Division:</strong> {selectedContact.division.name}</p>
                )}
              </div>
              <div className="info-section">
                <h3>
                  <span className="material-icons">contact_phone</span>
                  Contact Information
                </h3>
                {selectedContact.mobile_1 && (
                  <p>
                    <strong>Mobile 1:</strong>
                    <a href={`tel:${selectedContact.mobile_1}`}>
                      <span className="material-icons">phone_android</span>
                      {selectedContact.mobile_1}
                    </a>
                  </p>
                )}
                {selectedContact.mobile_2 && (
                  <p>
                    <strong>Mobile 2:</strong>
                    <a href={`tel:${selectedContact.mobile_2}`}>
                      <span className="material-icons">phone_android</span>
                      {selectedContact.mobile_2}
                    </a>
                  </p>
                )}
                {selectedContact.whatsapp_1 && (
                  <p>
                    <strong>WhatsApp 1:</strong>
                    <a href={`https://wa.me/${selectedContact.whatsapp_1.replace(/\D/g, '')}`} target="_blank" rel="noopener noreferrer">
                      <span className="material-icons">whatsapp</span>
                      {selectedContact.whatsapp_1}
                    </a>
                  </p>
                )}
                {selectedContact.whatsapp_2 && (
                  <p>
                    <strong>WhatsApp 2:</strong>
                    <a href={`https://wa.me/${selectedContact.whatsapp_2.replace(/\D/g, '')}`} target="_blank" rel="noopener noreferrer">
                      <span className="material-icons">whatsapp</span>
                      {selectedContact.whatsapp_2}
                    </a>
                  </p>
                )}
                {selectedContact.office_1 && (
                  <p>
                    <strong>Office 1:</strong>
                    <a href={`tel:${selectedContact.office_1}`}>
                      <span className="material-icons">phone</span>
                      {selectedContact.office_1}
                    </a>
                  </p>
                )}
                {selectedContact.office_2 && (
                  <p>
                    <strong>Office 2:</strong>
                    <a href={`tel:${selectedContact.office_2}`}>
                      <span className="material-icons">phone</span>
                      {selectedContact.office_2}
                    </a>
                  </p>
                )}
                {selectedContact.fax_1 && (
                  <p>
                    <strong>Fax 1:</strong>
                    <span className="material-icons">print</span>
                    {selectedContact.fax_1}
                  </p>
                )}
                {selectedContact.fax_2 && (
                  <p>
                    <strong>Fax 2:</strong>
                    <span className="material-icons">print</span>
                    {selectedContact.fax_2}
                  </p>
                )}
                {selectedContact.email && (
                  <p>
                    <strong>Email:</strong>
                    <a href={`mailto:${selectedContact.email}`}>
                      <span className="material-icons">email</span>
                      {selectedContact.email}
                    </a>
                  </p>
                )}
              </div>
              <div className="info-section">
                <h3>
                  <span className="material-icons">info</span>
                  Additional Information
                </h3>
                <p><strong>Last Updated:</strong> {new Date(selectedContact.updated_at).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-copyright">
            © 2025 Agri Staff Control Center. All rights reserved.
          </div>
          <div className="footer-developer">
            Developed by Television and Farm Broadcasting Service<br />
            Department of Agriculture
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
